#block-views-master-videos-block-cerec-story {
  padding-top: 0;
}
.view-master-videos {
  .views-row {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: lighten($color__hr, 28%);;
      margin: 40px 0;

      @media(min-width: $screen-sm-min) {
        margin: 50px 0;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  .video-list__item-title {
    @media(min-width: $screen-md-min) {
      margin-top: 0;
    }
  }

  .view-created {
    margin-bottom: 5px;
  }

}

.viewed__wrap {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 6px;
}