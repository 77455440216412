.view-master-videos.view-display-id-block_1 {

  .indent {
    padding-left: 40px;

    @media (min-width: 568px){
      padding-left: 50px;
    }
  }

  .indicator-new {
    display: inline-block;
    //background: $brand-primary;
    background: #62ba64;
    color: #fff;
    padding: .4em .8em;
    border-radius: 0 3px 3px 3px;
    position: absolute;
    top: 0;
  }


  .views-exposed-form {
    margin: 30px 0 50px;
    background: $color-gray--light;
    padding: 15px;

    #edit-submit-master-videos {
      @extend .btn-primary;
    }
  }

}