body.page-calendar {
  .pager {
    position: relative;
  }
  .view .date-nav-wrapper {
    .date-prev,
    .date-next {
      background: none;
    }

    .date-prev {
      left: 0;
      right: inherit;
    }
  }
}