// hide external link icon
span.ext {
  display: none;
}

.link--file {
  display: inline-block;
  margin-bottom: 5px;
  color: $brand-info;

  &:hover {
    color: $brand-info;
  }
}