.view-my-courses.view-id-my_courses {
  td,
  th {
    vertical-align: middle;
    padding: 15px;
  }

  .btn {
    margin: 0;
  }
}