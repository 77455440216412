body.page-node-quiz-results {

  #block-system-main {
    display: flex;
    flex-direction: column;
  }

  // move the quiz feedback to the top
  #quiz-summary {
    order: -1;
  }

  .quiz-passed,
  .quiz-failed {
    color: $text-color;
    padding: 15px;
    font-weight: bold;
  }

  .quiz-failed {
    background: #f46f6f;
  }

  .quiz-passed {
    background: $brand-success;
  }

  // border stopping halfway fix
  legend {
    float: left;
  }

  td {
    vertical-align: middle;
  }

}