.view-id-videos_overview.view-display-id-block_2 {

}

#block-views-videos-overview-block-2 {
	@media(min-width: 1440px) {
		margin-left: -100px;
		margin-right: -100px;
	}

	@media (min-width: 1500px){
		margin-left: -150px;
		margin-right: -150px;
	}

	.views-field-description {
		font-size: 15px;
	}
}