$forum-table-bp: 768px;

.view-advanced-forum-topic-list {

	#forum-pager-top {
		display: none;
	}

	.user-photo {
		margin-right: 10px;
		margin-bottom: 0;

		@media (min-width: $forum-table-bp) {
			margin-right: 0;
		}
	}

	.sticky-header {
		display: none;
	}

	thead {
		display: none;

		@media (min-width: $forum-table-bp) {
			display: table-header-group;
		}
	}

	th {
		text-align: center;
		vertical-align: middle;
		padding: 15px;

		img {
			display: inline-block;
			margin-left: 5px;
		}

		&.views-field-last-updated {
			@media (min-width: $forum-table-bp) {
				text-align: left;
			}
		}
	}

	tr {
		border-bottom: 1px solid #F5F5F5;
		padding: 15px 0;
		display: flex;
		flex-wrap: wrap;

		@media (min-width: $forum-table-bp) {
			display: table-row;
		}
	}

	td {
		display: block;
		@media (min-width: $forum-table-bp) {
			display: table-cell !important;
			padding: 15px;
		}

		&.views-field-picture {
			display: inline-block;
			width: 65px;
			align-self: center;
		}

		&.views-field-title {
			display: inline-block;
			width: calc(100% - 65px);

			> a:first-child {
				font-size: 20px;
			}
		}

		&.views-field-comment-count {
			width: 50%;
			text-align: left;
			margin-bottom: 10px;
			@media (min-width: $forum-table-bp) {
				width: 10%;
				text-align: center;
			}

			&::before {
				content: 'Replies: ';
				@media(min-width: $forum-table-bp) {
					display: none;
				}
			}
		}

		&.views-field-totalcount {
			width: 50%;
			text-align: left;
			@media (min-width: $forum-table-bp) {
				width: 10%;
				text-align: center;
			}

			&::before {
				content: 'Views: ';
				@media(min-width: $forum-table-bp) {
					display: none;
				}
			}
		}

		&.views-field-last-updated {
			width: 100%;
			@media(min-width: $forum-table-bp) {
				width: 25%;
			}

			&::before {
				content: 'Latest Post: ';
				@media(min-width: $forum-table-bp) {
					display: none;
				}
			}
		}
	}

	.forum-node-create-links {
		float: none;
		width: 100%;
	}

	.forum-node-create-links-top {
		float: none;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 30px;
		justify-content: space-between;

		&::before {
			content: 'Topics';
			@extend .h2;
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 3px;
			background: #f5f5f5;
		}
	}

	.forum-add-node {
		float: none;
		a {
			@extend .btn;
			@extend .btn-primary;
		}
	}

}

.forum-topic-legend,
#forum-sort,
.forum-tools {
	display: none;
}