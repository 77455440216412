//#block-views-events-block-1

.courses-list {

}

.courses-list__row {
  display: flex;
  flex-wrap: wrap;
}

.courses-list__item {
  width: 100%;
  //margin-bottom: 60px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: lighten($color__hr, 28%);
    margin: 40px 0;

    @media(min-width: $screen-sm-min) {
      margin: 50px 0;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  .field-content {
    display: flex;
    flex-wrap: wrap;

    @media(min-width: $screen-md-min) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}


.course-list__item-img,
.course-list__item-content{
  @media(min-width: $screen-md-min) {
    padding: 0 15px;
  }
}

.course-list__item-img {
  @media(min-width: $screen-md-min) {
    width: 50%;
  }
  @media(min-width: $screen-lg-min) {
    width: 60%;
  }
}

.course-list__item-content {
  @media(min-width: $screen-md-min) {
    width: 50%;
  }
  @media(min-width: $screen-lg-min) {
    width: 40%;
  }
}

.course-list__item-title {
  @media(min-width: $screen-md-min) {
    margin-top: 0;
  }
}