body.page-taxonomy-term-23 {
	#block-menu-block-3 {
		display: none;
	}

	#block-system-main {
		//display: none;
	}

	#views-exposed-form-master-videos-block-4 {
		button {
			@extend .btn-primary;
		}
	}
}