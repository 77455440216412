.ibimage_align-left {
  display: block;
  float: left;
  padding: 0 5px 5px 0;
}

.ibimage_align-right {
  display: block;
  float: right;
  padding: 0 0 5px 5px;
}