//training sample room
.page-node-306 {
  #block-cadstar-custom-cadstar-custom-user-register {
    padding-top: 0;

  }

  #user-register-form {
    #edit-profile-main {
      .panel {
        margin: 0;

      }
    }

    .panel {
      border: none;
      box-shadow: none;

      .panel-heading {
        background-color: transparent;
        border: none;
        padding: 0;

        .panel-title {
          font-weight: bold;
          font-size: 20px;
          padding: 0;
        }

      }

      .panel-body {
        padding: 0;
      }

    }



  }
}