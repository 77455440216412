$testimonial-nowrap-bp: 568px;

.view-testimonials.view-display-id-page {
  .field-content {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 0;
    border-bottom: 2px solid $color__hr--light;

    @media(min-width: $testimonial-nowrap-bp) {
      flex-wrap: nowrap;
    }
  }

  .testimonial__img {
    margin-bottom: 15px;
    flex-shrink: 0;
    margin-right: 30px;

    img {
      max-width: 200px;
    }
  }

  // first testimonial
  .views-row-first {
    .field-content {
      padding-top: 0;
    }
  }

  // last testimonial
  .views-row-last {
    .field-content {
      border-bottom: 0;
      padding-bottom: 20px;
    }
  }
}