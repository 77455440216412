.view-photos {
  .views-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }

  .views-column {
    margin-bottom: 10px;
    width: 50%;
    padding: 5px;

    @media(min-width: 414px) {
      width: calc(100%/3);
    }
    @media(min-width: $screen-md-min) {
      width: 25%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}