.main-container {
  ul {
    padding-left: 20px;
    line-height: 2.2;
  }

  aside {
    ul {
      line-height: 1.5;
    }
  }
}

