.btn {
  padding: .6em 1.1em;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 10px;
  color: #fff;
  transition: all .2s;
  white-space: initial;
}

.btn-default {
  background: $brand-default;
  border: none;
  
  &:hover {
    background: darken($brand-default, 10%);
    color: #fff;
  }
}

.btn-primary,
.btn-info,
.btn-success {
  border: none;
}

.btn-lg {
  padding: 1em 2.4em;
  font-size: $font-size-base;
}


.btn i {
  margin-right: 5px;
}


/*===============================
    # Custom Button Variations
 ===============================*/
.btn--square {
  border-radius: 4px;
}