.card {
  margin-bottom: 30px;
  text-align: center;

  &--last {
    margin-bottom: 0;
  }

  @media(min-width: $screen-sm-min) {
    margin-bottom: 0;
    text-align: left;
  }

  img {
    margin: 0 auto;
  }
}

.card--2 {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 360px;

  @media(min-width: $screen-sm-min) {
    margin-bottom: 30px;
  }

  @media(min-width: $screen-md-min) {
    margin-bottom: 0;
  }

  .card__body {
    position: absolute;
    top: 20px;
    left: 0;
    right: 25px;
    background: $color__card-body--transparent;
    padding: 15px;
    text-align: left;
  }
}