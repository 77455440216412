#block-block-13 {
  text-align: center;

  .block-title {
    margin-top: 20px;
    @media(min-width: $screen-sm-min) {
      margin-top: 5px;
    }
  }

  img {
    margin: 0 auto;
  }
}