/*====================================
    # Play Sample & Login Section
 ====================================*/

#block-block-15 {
  text-align: center;

  @media(min-width: $screen-sm-min) {
    text-align: left;

    .col-sm-6:last-child {
      text-align: right;
    }
  }

  .by-banner {
    @media(min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
    }
  }


  .btn {
    @media(min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }

  .btn:last-child {
    margin-left: 10px;
  }
}