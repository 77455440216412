form.search-form {

  .form-wrapper {
    margin: 0 10px;
    position: relative;
    width: 250px;

    @media (min-width: $screen-sm-min) {
      margin: 0;
    }
  }

  // bootstrap button
  .input-group-btn {
    display: none;
  }

  // submit buttons=
  .form-submit {
    clip: initial;
    height: auto;
    top: 50%;
    right: 10px;
    z-index: 999;
    background: transparent;
    color: #ccc;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);

    &:hover {
      color: #999;
    }
  }

  input.form-control {
    //width: 250px;
  }
}