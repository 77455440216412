body.node-type-message-center {

  .blog__categories,
  .blog__tags {
    margin-top: 15px;
  }

  .field-name-taxonomy-vocabulary-9,
  .field-name-taxonomy-vocabulary-10 {
    display: inline;

    .field-items,
    .field-item {
      display: inline;
    }
  }

  .field-name-field-share {
    margin-top: 15px;
  }

  .field-name-body {
    @extend .clearfix;
  }

}