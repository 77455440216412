body.page-messages {
	.action-links {
		@extend .list-unstyled;
		margin-top: 15px;
	}

	#edit-delete {
		margin-bottom: 15px !important;
	}

	.form-item-operation {
		display: block;
		margin-bottom: 15px !important;
	}

	select {
		margin-left: 0 !important;
	}

	#edit-filter,
	.form-item-tag-add,
	#edit-tag-add-submit
	{
		display: none;
	}
}