.photoswipe-gallery {
  .field-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  .field-item {
    width: 50%;
    padding: 5px;

    @media(min-width: 414px) {
      width: calc(100%/3);
    }
    @media(min-width: 568px) {
      width: 25%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*===========================
    # Lightbox
 ===========================*/
.pswp__item {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.pswp__zoom-wrap {
  position: static;
  transform: none !important;
  width: auto;
}

.pswp__img {
  position: static;
}