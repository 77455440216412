aside {
  //line-height: 1.8;
  font-weight: 300;
  background: $color-gray--light;
  padding-top: 40px;
  padding-bottom: 40px;

  .btn {
    display: block;
    border-radius: 4px;
  }

  // being more specific to override bootstrap classes
  &.col-md-3 {
    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
      margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
      padding-left: calc(50vw - 360px);
      padding-right: calc(50vw - 360px);
    }
  }

  @media(min-width: $screen-md-min) {
    background: none;
  }



  ul {
    padding: 0;
  }

  .block {
    padding: 0;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .block-title {
    font-size: 20px;
    text-align: left;
    margin-top: 0;

    &::after {
      display: none;
    }
  }

  #block-views-message-center-block-2,
  #block-privatemsg-privatemsg-menu,
  #block-views-message-center-block-3,
  #block-views-article-archive-block-1,
  .view-blog.view-display-id-block_1,
  .menu.nav {
    padding-left: 0;
    line-height: 1.3;

    ul {
      padding-left: 20px;
      list-style: none;
      margin-bottom: 0;
    }

    > ul,
    .item-list > ul {
      padding-left: 0;
    }


    > li a {
      padding-left: 0;
      padding-right: 0;
      &:hover {
        background: none;
        text-decoration: underline;
      }
    }

    li {
      &:last-child::after {
        display: none;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #f5f5f5;
      }
    }

    a {
      display: inline-block;
      padding: 10px 0;
    }

    .field-content {
      display: block;
    }

    .more-link a {
      @extend .btn, .btn-sm, .btn-primary;
      margin: 20px 0 15px;
    }
  }



  /*=============================
      # Blog Archive Block
   =============================*/
  #block-views-article-archive-block-1 {
    ul {
      list-style: none;
      padding: 0;
    }

    li {
      color: #bbb;
    }
  }

  /*===========================
      # Message Center Block
   ===========================*/
  #block-views-message-center-block-3 {
    a {

    }
    .field-content {
      display: inline-block;
      margin-right: 5px;
    }
  }


  .block-title {
    &::after {
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      background: $hr-border;
      margin: 10px 0 0;
    }
  }


  /*===========================
      # Recent Blog Posts
   ===========================*/
  #block-views-blog-block-1,
  #block-views-message-center-block-3 {
    .views-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $hr-border;
      padding: 12px 0;

      &:last-child {
        border-bottom: none;
      }

      &::after {
        display: none;
      }

      a {
        padding: 0;
      }

    }

    .views-field {
      flex: 1;
    }

    .views-field-field-featured-image {
      margin: 0 10px 0 0;
      max-width: 80px;

      .field-content {
        margin-right: 0;
        display: flex;
      }
    }

  }

  /*=============================
      # Private Messages Block
   =============================*/
  #block-privatemsg-privatemsg-menu {
    .new-message-link a {
      color: $brand-info;
    }
  }

}