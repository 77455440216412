#navbar {
	font-weight: 400;
	padding: 15px 0;
}

.navbar {
	background: #fff;
	border: none;
	z-index: 10;
	margin-bottom: 0;
}

.logo {
	@media(min-width: $grid-float-breakpoint) {
		//padding: 10px 0;
	}

	img {
		width: auto;
		height: 55px;

		@media(min-width: $screen-sm-min) {
			height: 60px;
		}
	}
}

.navbar-header {
	float: none;
}

.header__top-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.header-top__items {
		display: none;

		@media(min-width: $grid-float-breakpoint) {
			display: flex;
			align-items: center;
		}
	}

	// Menu
	.menu.secondary {
		margin-top: 0;
		.last.leaf a {
			margin-right: 0;
		}
	}

	form.search-form {
		margin-right: 8px;
	}

}

.header__bottom-wrap {

	.nav {
		max-width: 100%;
	}

	.menu.secondary {
		@media(min-width: $grid-float-breakpoint) {
			display: none;
		}
	}

	.navbar-collapse {
		padding: 0;
	}

	.last.leaf {
		@media(min-width: $grid-float-breakpoint) {
			margin-right: -13px;

			&.active {
				margin-right: 0;
			}
		}
	}

	// Search Form
	form.search-form {
		@media(min-width: $grid-float-breakpoint) {
			display: none;
		}
	}
}