.fa {
  transition: all .2s;
}

.fa-facebook {
  &:hover {
    color: $facebook;
  }
}

.fa-instagram {
  &:hover {
    color: $instagram;
  }
}

.fa-twitter {
  &:hover {
    color: $twitter;
  }
}

.fa-youtube {
  &:hover {
    color: $youtube;
  }
}

.fa-linkedin {
  &:hover {
    color: $linkedin;
  }
}