#block-views-testimonials-block-1 {

  .owl-item  .views-field-nothing {
    @extend .container;
  }

  img {
    margin-bottom: 30px;

    @media(min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }



  .more-link {
    text-align: center;
    margin-top: 30px;

    a {
      @extend .btn, .btn-lg, .btn-primary;
    }
  }
}