body {
  font-weight: 300;
  color: #222;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  //text-transform: uppercase;
}


h1 {
  font-size: floor(($font-size-base * 1.8));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 2));
  }
}
h2 {
  font-size: floor(($font-size-base * 1.6));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 1.8));
  }
}
h3 {
  font-size: floor(($font-size-base * 1.4));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 1.6));
  }
}
h4 {
  font-size: floor(($font-size-base * 1.2));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 1.4));
  }
}
h5 {
  font-size: floor(($font-size-base * 1));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 1.2));
  }
}
h6 {
  font-size: floor(($font-size-base * .8));
  @media(min-width: $screen-sm-min) {
    font-size: floor(($font-size-base * 1));
  }
}