.book-navigation {
	.book-toc {
		display: none;
	}

	.page-links {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 30px;
		margin-top: 15px;
		margin-bottom: 25px;
		border-bottom: 1px solid $hr-border;

		.book-nav__page-link {
			display: inline-block;
			border: none;
			text-transform: none;
			font-weight: 500;
			//padding: 0;
			//color: #b6b6b6;
			//font-weight: bold;
			//margin: 10px 0;
			//float: none;
			//transition: all .2s;

			&:hover {
				//color: $text-color;
				//text-decoration: none;
				//background: none;
			}
		}

		.book-nav__prev-link {
			margin-right: 40px;
		}

		.book-nav__next-link {
			margin-left: auto;
		}
	}
}

.book-navigation__hr {
	margin-top: 40px;
}