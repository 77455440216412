.user-photo {
  $user-photo-size: 55px;
  border: 1px solid #aaa;
  border-radius: $user-photo-size;
  overflow: hidden;
  width: $user-photo-size;
  height: $user-photo-size;
  margin-bottom: 10px;
  margin-right:10px;
}
