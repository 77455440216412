#block-views-homepage-products-block {
  .view-content {
    @extend .container;
  }

  img {
    max-width: 120px;
  }

  .media-body {
    vertical-align: middle;
  }

  .more-link {
    text-align: center;
    margin-top: 30px;
    a {
      @extend .btn, .btn-lg, .btn-primary;
    }
  }
}