body.node-type-course,
body.node-type-course-page {

}

// mobile modal lesson nav
#block-gm-course-access-gm-course-access-book-nav-block {
  background: #fcfcfc;
  @extend .full-width-section;

  @media(min-width: $screen-md-min) {
    display: none;
  }

  .course-page__modal-trigger {
    text-align: center;
  }

  // modal menu
  .menu.nav {
    padding-left: 0;

    > li > .menu.nav {
      padding-left: 15px;
    }
  }
}


/*===========================
    # Course
 ===========================*/
body.node-type-course {
  #block-system-main {
    //border-bottom: 1px solid $hr-border;
    padding-bottom: 25px;
  }

  .field-name-field-featured-image {
    img {
      margin-bottom: 15px;
    }
  }
}


/*===========================
    # Course Pages
 ===========================*/
body.node-type-course-page {
  .field-name-field-featured-image {
    margin-bottom: 15px;
  }

  .cdt-btn,
  .rst-btn {
    display: inline-block;
    color: $brand-info;
  }

  .cdt-btn {
    margin-bottom: 8px;
  }
}

/*==============================
    # Course Quiz (intro page)
 ==============================*/
body.node-type-quiz {

  .certificate-link {
    a {
      @extend .btn, .btn-primary;
      //margin-bottom: 25px;
    }
  }

  .table-responsive {
    border: none;
  }

  #quiz-view-table {
    margin-top: 30px;
    border: none;

    td,
    th {
      vertical-align: middle;
      padding: 15px;
    }
  }

  .quiz-start-link {
    @extend .btn, .btn-info, .btn--square;
  }
}


/*=========================================
    # Course Quiz (actual quiz questions)
 =========================================*/
body.page-node-take {
  .table {
    td {
      padding: 8px;
      vertical-align: middle;

      p {
        margin-bottom: 0;
      }
    }

    .form-radio {
      position: relative;
      margin-right: 0;
    }

  }

  .form-actions {
    button {
      @extend .btn--square;
    }
  }
}
