#block-views-homepage-slider-block {
  padding: 0;
}

.view-homepage-slider {
  line-height: 1.5;

  .slide-wrap {
    color: #fff;
    text-align: center;
    //padding: 50px 0;

    @media(min-width: $screen-md-min) {
      //padding: 70px 0;
    }
    @media(min-width: $screen-lg-min) {
      //padding: 80px 0;
    }

    h1 {
      margin-top: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      line-height: 1.7;
      text-align: left;
    }
  }

  .owl-controls {
    position: absolute;
    bottom: 5px;
    width: 100%;
  }

  .item-0 {
    .slide-wrap {
      background-position: 50% 50% !important;
    }
  }

  .owl-wrapper {
    display: flex !important;
    //align-items: stretch;
    //
    .owl-item {
      display: flex !important;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
    }

    .item-odd,
    .item-even {
      width: 100%;
      display: flex;
    }

    .views-field-nothing {
        display: flex;
        width: 100%;
    }

    .field-content {
      display: flex;
      width: 100%;
    }

    .slide-wrap {
      width: 100%;
    }

  }

}

.checklist__wrap {
  width: auto;
  margin: 0 auto;
  float: none;

  & > .row {
    display: inline-block;

    & .checklist:first-child {
      margin-right: 25px;
    }
  }

  .checklist {
    width: auto;
  }
}