.panel-heading {
	padding: 0;
	display: flex;
	align-items: center;

	.panel-img {
		max-width: 100px;
		height: auto;
		margin: 15px 0 15px 15px;
	}

	h3 {
		margin: 0;
		flex: 1;
	}

	a,
	.panel-title {
		display: block;
		padding: 15px;
		padding-right: 30px;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}
}

fieldset.panel {
	@extend .clearfix;

	legend.panel-heading,
	.panel-collapse,
	.panel-body {
		float: left;
		margin-bottom: 0;
		width: 100%;
	}

}

.panel.collapsible {
	.panel-title {
		&::after {
			content: '\f107';
			font-family: "FontAwesome";
			display: block;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			margin-left: auto;
		}
	}
}

.panel.collapsed {
	height: auto !important;
	border-width: 1px !important;
}