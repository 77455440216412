.page-header-wrap {
  background: $color__page-header;

  h1 {
    text-align: center;
    font-weight: 500;
    margin: 0;
    font-size: 32px;

    @media(min-width: 414px) {
      font-size: 36px;
    }

    @media(min-width: 736px) {
      font-size: 41px;
    }
  }

  em {
    display: block;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }
}

.page-header__inner-wrap {
  padding: 40px 0;

  @media(min-width: $screen-md-min) {
    padding: 60px 0;
  }
}