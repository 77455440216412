/*===========================
    # Footer
 ===========================*/
.footer {
  font-weight: 400;
}

.footer-wrap {
  background: #222;
  color: #fff;
  padding: 15px 0;
}

.region-footer {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;

  a {
    color: #fff;
  }

  > .block {
    padding: 25px 15px;
    width: 100%;
    max-width: 384px;
    @media(min-width: $screen-sm-min) {
      width: 50%;
    }
    @media(min-width: $screen-md-min) {
      width: 50%;
      max-width: 50%;
    }
    @media(min-width: $screen-lg-min) {
      width: 25%;
    }
  }

  .block-title {
    margin-top: 0;
    font-size: 20px;
    text-align: left;

    &::after {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 0;
      background: $color__hr--footer;
      height: 1px;
    }
  }

  .nav {
    li {
      a {
        transition: all .2s;
        padding: 5px 0;
        &:hover {
          color: #ccc;
          background-color: transparent;
        }
      }

      &:last-child {
        a {
          padding-bottom: 0;
        }
      }
    }
  }
}


/*===========================
    # Footer Credits
 ===========================*/
.footer-wrap--credits {
  background: #111;
  color: $color__footer-credits;

  @media(max-width: 549px) {

    .pull-left {
      margin-bottom: 10px;
    }

    .pull-left,
    .pull-right {
      float: none !important;
      text-align: center;

    }
  }
}