.view-homepage-forum {
	@extend .container;

	.view-header {
		text-align: center;
		margin-bottom: 30px;
	}

	.home-forum__wrap {
		display: flex;
		flex-wrap: wrap;

		&::before,
		&::after {
			display: none;
			content: none;
		}
		.views-column {
			display: flex;
		}

		.views-field-nothing {
			width: 100%;
			background: $color__card-body;
		}
	}

	.home-forum__post {
		width: 100%;
		padding: 0 15px;
		margin-bottom: 30px;

		@media(min-width: $screen-sm-min) {
			width: 50%;
		}


		.field-content {
			height: 100%;
		}


		.discussion-post {
			height: 100%;
			padding: 15px;
		}

		.discussion-post__desc {
			margin-bottom: 0;
		}

	}

	.more-link {
		text-align: center;
		a {
			@extend .btn, .btn-lg, .btn-primary;
		}
	}
}