.block {
  padding: 40px 0;
}

.block-title {
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 2px;
    background: $color__hr;
    margin: 30px auto 40px;
  }
}