/*====================================
    # The Klim Institute Blog & Message Center Section
 ====================================*/

.view-homepage-blog-message-center {
	text-align: center;

	.view-content {
		@extend .container;
	}

	.btn {
		margin-top: 30px;
	}

	.card {
		background: $color__card-body;
		max-width: 345px;
		margin: 0 auto 35px;
		text-align: left;

		a {
			color: #333;
		}
	}

	.card__content-wrap {
		padding: 15px;
	}

	.home-blog-posts__wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		&::before,
		&::after {
			display: none;
		}

		.views-column {
			display: flex;
			margin-bottom: 30px;
			justify-content: center;
		}

		.views-column-5, .views-column-6, .views-column-7, .views-column-8 {
			@media(min-width: $screen-lg-min) {
				margin-bottom: 0;
			}
		}


		.views-field-nothing {
			background: $color__card-body;
		}


		.card {
			@media(min-width: $screen-lg-min) {
				height: 100%;
				margin-bottom: 0;
			}
		}
	}

	.more-link a {
		@extend .btn, .btn-lg, .btn-primary;
	}
}