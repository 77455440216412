#comments {

  .comment {

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #F5F5F5;
      margin-bottom: 15px;
      margin-top: 15px;
      z-index: 10;
      visibility: visible;
    }

    .links {
      margin-bottom: 0;
    }
  }

  .indented {
    padding-left: 15px;

    .comment {
      background: #F5F5F5;
      padding: 25px;

      &::after {
        width: 0;
        height: 0;
        margin: 0;
      }
    }
  }

  > .indented {
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #F5F5F5;
      margin-bottom: 15px;
      margin-top: 15px;
      margin-left: -15px; // opposite of padding that indents it
      z-index: 10;
      visibility: visible;
    }

    .indented {
      margin-top: 15px;
    }
  }

}

.comment__meta-wrap {
  display: flex;
  flex-direction: column;

  @media (min-width: 568px) {
    flex-direction: row;
    align-items: center;
  }
}


/*===========================
    # Comment Form
 ===========================*/
form.comment-form {
  .filter-wrapper,
  .grippie {
    display: none;
  }
}