.social-icons {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .fa {
    font-size: 28px;
    margin-right: 13px;
  }
}