.media img {
  max-width: none;
}

img,
.media-element-container.media img {
  @extend .img-responsive;
	height: auto !important;
}

.file-icon {
  display: inline-block;
}