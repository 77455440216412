$forum-comments-bp: 768px;

body.node-type-forum {

  /*===========================
      # Header Meta
   ===========================*/
  .forum-topic-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &::before {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #F5F5F5;
      margin-bottom: 15px;
      margin-top: 15px;
      z-index: 10;
      visibility: visible;
    }

    #forum-topic-top {
      display: none;
    }

    .topic-new-post-link,
    .last-post-link {
      display: none;
    }

    .topic-reply-link {
      order: -1;
      a {
        @extend .btn;
        @extend .btn-primary;
      }
    }


  }

  /*====================================
      # First Post (the thread starter)
   ====================================*/
  #block-system-main {
    > .forum-post {
      &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background: #F5F5F5;
        margin-bottom: 15px;
        margin-top: 15px;
        z-index: 10;
        visibility: visible;
      }

      .forum-post-wrapper {
        @media (min-width: $forum-comments-bp) {
          display: flex;
        }
      }

      .forum-post-panel-sub {
        float: none;
      }

      .forum-post-panel-main {
        margin-left: 0;
      }

      .author-pane {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        @media (min-width: $forum-comments-bp) {
          flex-direction: column;
        }
      }
    }
  }

.forum-post-panel-main {
	flex: 1;
}

  /*===========================
      # User Picture
   ===========================*/
  .user-picture {
    $user-photo-size: 55px;
    border: 1px solid #aaa;
    border-radius: $user-photo-size;
    overflow: hidden;
    width: $user-photo-size;
    margin-right: 10px;

    @media (min-width: $forum-comments-bp) {
      margin-top: 10px;
    }
  }

}

/*===========================
    # Comments
 ===========================*/
#forum-comments {
  .forum-post {
    padding: 15px 0;

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #F5F5F5;
      margin-bottom: 15px;
      margin-top: 15px;
      z-index: 10;
      visibility: visible;
    }
  }

  .indented {
    padding-left: 15px;

    .forum-post {
      background: #F5F5F5;
      padding: 15px;
    }

  }

  > .indented {
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: calc(100% + 15px);
      background: #F5F5F5;
      margin: 15px -15px;
      z-index: 10;
      visibility: visible;
    }
  }

  .forum-post-panel-sub {
    width: 100%;
    float: none;

    @media (min-width: $forum-comments-bp) {
      width: 150px;
    }
  }

  .forum-post-info {
    margin-bottom: 15px;
  }

  .forum-post-wrapper {
    @media (min-width: $forum-comments-bp) {
      display: flex;
    }
  }

  .forum-post-panel-main {
    margin-left: 0;
    flex: 1;
  }


  .author-pane {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    @media (min-width: $forum-comments-bp) {
      flex-direction: column;
      align-items: flex-start;
    }
  }



  .forum-post-title {
    font-weight: bold;
    font-size: 18px;
  }

  // Top link (go to top)
  .forum-jump-links {
    display: none;
  }

  // body of comment
  .forum-post-panel-main {
    min-height:0;
  }
}

