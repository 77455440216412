html,
body {
  overflow-x: hidden;
}

.alert-error {
  background-color: #fef5f1;
  border-color: #ed541d;
  color: #8c2e0b;
}

#uc-cart-view-form {
  .form-control.form-text {
    width: auto;
  }

}
