body.front {
  .region-front-page {
    section:nth-child(odd) {
      background: #fff;
    }
    section:nth-child(even) {
      background: $color__section-bg;
    }

    section {
      .block-title {
        margin-top: 0;
      }
    }
  }
}

/*===========================
    # Homepage Slider
 ===========================*/

// see modules/_hero-slider.scss


/*=======================================================
    # The Klim Institute Blog & Message Center Section
 =======================================================*/

// see views/_homepage-blog-message-center.scss


/*====================================
    # Cerec Success Online Series Section
 ====================================*/

// see blocks/_block-13.scss


/*====================================
    # Digital Discussions Section
 ====================================*/

// see views/_homepage-forum.scss


/*====================================
    # What others are saying about Klim Institute Section
 ====================================*/

// see views/_homepage-testimonials-block.scss


/*====================================
    # Klim Institute Favored Products
 ====================================*/

// see views/_homepage-products-block.scss


/*====================================
    # Sponsors Section
 ====================================*/

// see views/_homepage-sponsors-block.scss