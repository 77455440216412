.navbar-toggle {
  border: none;
  margin: 0;

  @media(min-width: $screen-sm-min) {
    //margin: 32px 15px 0;
  }
}

.navbar-collapse {
  background: #fff;
}

.navbar-nav {
  text-transform: uppercase;

  @media(min-width: $grid-float-breakpoint) {
    float: right;
  }

  .caret {
    display: none;
  }
}

/*====================================
    # Primary Nav Menu
 ====================================*/
.primary-menu {
  @media(min-width: $grid-float-breakpoint) {
    margin-top: 6px;
  }
}

/*====================================
    # Secondary Menu
 ====================================*/

.navbar-nav.secondary {
  margin-top: 8px;

  @media(min-width: $grid-float-breakpoint) {
    font-size: 14px;
  }

  > li > a {
    @media (min-width: $grid-float-breakpoint) {
      color: #a6a6a6;
    }
  }
}

.navbar-default .navbar-nav .menu-item--cta {
  background: $brand-primary;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  margin-right: 10px;
  margin-top: 4px;
  transition: all .2s;
  display: inline-block;
  margin-left: 15px;

  @media(min-width: $grid-float-breakpoint) {
    margin-left: 0;
  }

  
  &:hover {
    background: darken($brand-primary, 10%);
    color: #fff;
  }
}

.nav>li>a {
  @media(min-width: $grid-float-breakpoint) {
    padding: 10px 9px;
  }
  @media(min-width: $screen-lg-min) {
    padding: 10px 15px;
  }
}


/*===========================
    # Dropdowns
 ===========================*/
.dropdown-menu {
  position: static;
  float: none;
  @media(max-width: $grid-float-breakpoint-max) {
    box-shadow: none;
    border: none;
    > li {
      > a {
        line-height: 21px;
        padding: 5px 15px 5px 25px;
        color: #676767;
      }
    }
  }
  @media(min-width: $grid-float-breakpoint) {
    position: absolute;
    float: left;
  }
}