.view-downloads {
  .views-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }

  .views-column {
    width: 50%;
    padding: 5px;
    margin-bottom: 25px;

    @media (min-width: 568px) {
      width: calc(100%/3);
    }
  }

  .views-field-title {
    margin-top: 15px;
  }

  .views-field-changed {
    font-size: 14px;
    font-style: italic;
  }
}