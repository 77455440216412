/*
|--------------------------------------------------------------------------
| Quick changes
|--------------------------------------------------------------------------
*/

#page-header {
  background-position: center;
  background-size: cover;
}

.text-white {
  color: #fff;
}

.text-info {
  color: $brand-info;
}
.btn-white {
  background: #fff;
  color: #1282B1;
}

.font-light {
  font-weight: lighter;
}
.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}


#page-header.has-image {
  display: flex;
  padding: 2em 0;
}

@media (min-width: 768px){
  #page-header.has-image {
    padding: 3em 0;
  }
}

@media (min-width: 992px){
  #page-header.has-image {
    padding: 4em 0;
  }
}

@media (min-width: 1200px){
  #page-header.has-image {
    padding: 6em 0;
  }
}

#page-header.has-image .page-header__inner-wrap {
  display: flex;
  flex-direction: column;
  margin: auto;
  background: rgba(0,0,0,.7);
  padding: 1em;
}

@media (min-width: 500px){
  #page-header.has-image .page-header__inner-wrap {
    padding: 2em;
  }
}

@media (min-width: 992px){
  #page-header.has-image .page-header__inner-wrap {
    padding: 2em 3em;
  }
}

.bg-light {
  background: #F5F5F5;
}

.bg-light-info {
  background: #D5E5ED;
}

.btn-wrap {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;

  @media(min-width: 768px) {
    flex-direction: row;
  }

  > a {
    margin: .5em 1em;
  }
}

.display-4 {
  @media(min-width: 768px) {
    font-size: 3.6rem;
  }
}

.has-underline {
  display: flex;
  flex-direction: column;
  line-height: 1.4;

  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background: #ccc;
    margin: .6em auto 1em;
  }
}

.list-wrap--with-header {
  border: 2px solid #F5F5F5;
  position: relative;
  padding-top: 2em;
  padding: 2em 1em 1em;
  max-width: 600px;
  margin: auto;

  > h4 {
    position: absolute;
    top: -1.2em;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    white-space: nowrap;
    padding: 0 1em;
  }

  > ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }
}

.list--checkmarks {
  padding-left: 0 !important;

  li {
    position: relative;
    padding-left: 1.5em;

    &::before {
      font-family: 'FontAwesome';
      content: '\f00c';
      color: #1BA4E7;
      margin-right: 5px;
      position: absolute;
      left: 0;
    }
  }
}

#block-block-22 {
  .btn-wrap {
    margin-top: 1.5em;
  }
}


.view-id-videos_overview.view-display-id-block_3 {
  .view-footer {
    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        color: $brand-info;
      }
    }

    .fa {
      margin-right: .5em;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Alert Info
|--------------------------------------------------------------------------
*/
.alert-info {
  background: #1282B1;
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items:center;

  @media(min-width: 768px) {
    flex-direction:row;
  }

  > span {
    @media(min-width: 768px) {
      flex: 1;
    }
  }

  > .btn {
    margin-top: 1em;
    padding: .5em 2em;
    @media(min-width: 768px) {
      margin-top: 0;
      margin-left: 1em;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Region Highlighted
|--------------------------------------------------------------------------
*/
.region-highlighted {
  .block {
    padding: 0;
    margin: 1em 0;

    &.full-width-section {
      @extend .full-width-section;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:last-child {
      > .alert {
        margin-bottom: 0;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Bootstrap 4-esque Utils
|--------------------------------------------------------------------------
*/
.p-0 {
  padding: 0;
}

.p-2 {
  padding: 2rem;
}

.py-2 {
  padding: 2rem 0;
}

.mt-0 {
  margin-top: 0;
  &.mt-0 {
    margin-top: 0;
  }
}

.mt-md-0 {
  @media (min-width: 992px){
    margin-top: 0;
  }
}

.mb-2 {
  margin-bottom: 2rem;
}

.bg-gray-700 {
  background: #DEDEDE;
}

.hr-2 {
  border-width: 2px;

  &.bg-gray-700 {
    @extend .bg-gray-700;
  }
}

.text-body {
  color: $text-color;
}

.border-1 {
  border: 1px solid $color-gray--medium;
}


/*
|--------------------------------------------------------------------------
| Online CE Lessons Page
|--------------------------------------------------------------------------
*/
body.page-node-3589 {
  #block-system-main {
    padding: 0;
  }

  #page-header.has-image .page-header__inner-wrap {
    background: rgba(33, 155, 233, .7);
  }

  ol.breadcrumb {
    display: none;
  }
}


/*
|--------------------------------------------------------------------------
| Front page
|--------------------------------------------------------------------------
*/
.online-ce-cta__img {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.online-ce-cta__content {
  width: 100%;

  @media (min-width: 992px){
      width: 50%;
  }

  & > div {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}