.pagination {
  display: flex;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;

  > li {
    &:first-child,
    &:last-child {
      > a,
      > span {
        border-radius: 0;
      }
    }

    > a,
    > span {
      border: none;
      height: 100%;
    }
  }
}