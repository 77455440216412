.view-master-videos.view-display-id-block_4 {
  .views-row {
    display: flex;
    flex-wrap: wrap;


    &::before,
    &::after {
      display: none;
    }
  }

  .video-view__row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .video-list__item-title {
    @extend .h5;
    font-weight: normal;
    line-height: 1.5;
  }

  .video__thumb img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
