.full-width-section {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px);
    padding-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px); // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px);
    padding-right: calc(50vw - 570px);
  }
}


.py-0.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-md-3 {
  @media (min-width: 992px){
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.bg-primary--important {
  background: $brand-primary !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.lead--small {
  @extend .lead;
  font-size: 16px !important;
  font-weight: 100;

  @media (min-width: 768px){
      font-size: 20px !important;
  }
}

.text-xs-center {
  @media (max-width: 767px){
    text-align: center;
  }
}

.text-sm-center {
  @media (min-width: 768px) and (max-width: 991px){
      text-align: center;
  }
}

