.product-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	&__product {
		display: flex;
		flex-basis: 100%;
		padding: 15px;
		text-align: center;
		//margin-bottom: 15px;

		@media(min-width: 568px) {
			flex-basis: 50%;
		}

		@media(min-width: $screen-sm-min) {
			flex-basis: calc(100% / 3);
		}

		@media(min-width: $screen-md-min) {
			flex-basis: 50%;
		}

		@media(min-width: $screen-lg-min) {
			flex-basis: calc(100% / 3);
		}

		img {
			margin: 0 auto 15px;
		}

		h4 {
			margin-top: 5px;
		}
	}

	.views-field-nothing {
		display: flex;
		width: 100%;
		border: 1px solid $hr-border;
		border-radius: 4px;
	}

	.field-content {
		display: flex;
		width: 100%;
	}

	.product__inner-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		padding: 15px;
		max-width: 300px;
	}

	.btn {
		margin-top: auto;
	}
}

.view-online-courses {
	.product-grid {
		.product__price {
			margin-top: auto;
		}

		.btn {
			margin-top: 0;
		}
	}
}