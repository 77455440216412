.view-course-pages.view-id-course_pages  {

  .views-row {
    margin-bottom: 40px;

    &:last-child{
      margin-bottom: 0;
    }
  }

}