body.node-type-course-product {

  /*===========================
      # Image
   ===========================*/
  .field-name-uc-product-image {
    margin-bottom: 15px;
  }

  /*===========================
      # Price
   ===========================*/
  .display-price {
    float: none;
    text-align: left;
    padding: 0;
  }

  /*===========================
      # Add to cart form
   ===========================*/
  .form-actions {
    .uc_out_of_stock_throbbing,
    .uc-out-of-stock-instock {
      display: none !important;
    }

    .node-add-to-cart {
      @extend .btn-info;
    }
  }

  /*===========================
      # CTA Wrap
   ===========================*/
  .product-cta-wrap {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    .display-price {
      margin-bottom: 5px;

      @media(min-width: $screen-sm-min) {
        text-align: center;
      }
    }

    .btn {
      margin: 5px 0 0;
      @extend .btn--square;

      @media(min-width: $screen-sm-min) {
        width: 100%;
      }
    }

    .form-actions {
      margin-bottom: 0;
    }
  }

}