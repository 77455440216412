.cerec-video-list__row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

.cerec-video-list__item {
	padding: 15px;
	width: 100%;
	display: flex;
	flex-direction: column;

	@media(min-width: 414px) {
		width: 50%;
	}
	@media(min-width: 667px) {
		width: calc(100% / 3);
	}

	.views-field-name {
		//background: $color-gray--medium;
		background: #4B4B4B;
		padding: 15px;
		//flex: 1;

		a {
			//color: $text-color;
			color: #fff;
			font-size: 18px;
			font-weight: 500;
		}
	}

	.views-field-description {
		background: $color-gray--light;
		padding: 15px;
		flex: 1;
	}
}