.view-blog.view-display-id-page_1, // blog view
.view-blog.view-display-id-page_3, // blog category view
.view-article-archive.view-display-id-page_1, // blog archive view (list of posts not list of months)
.view-message-center.view-display-id-page_1, // message center view
.view-message-center.view-display-id-page_3 { // message center category view

	overflow-x: hidden;

	.views-row {
		padding: 40px 0;
		border-bottom: 2px solid #f5f5f5;
		@extend .clearfix;

		&:first-child {
			padding-top: 0;
		}
	}

	.views-field-title {
		.field-content {
			margin-top: 0;
		}
	}

	.views-field-taxonomy-vocabulary-4,
	.views-field-taxonomy-vocabulary-9 {
		margin-bottom: 15px;

		.field-content {
			display: inline;

			a {
				display: inline-block;
			}
		}
	}

	.views-field-field-featured-image {
		margin-bottom: 15px;
	}

	//img, iframe {
	//  float: none !important;
	//  display: block;
	//}

	.more-link {
		@extend .btn;
		@extend .btn-primary;
		//display: block;
		display: none;
		width: 160px;
		margin-top: 15px;
		clear: both;
	}
}