.profile-page__content{

  dt {
    @media(min-width: $screen-sm-min) {
      width: 100px;
      float:left;
      clear: both;
    }
  }
  dd {
    margin-bottom:15px;
    @media(min-width: $screen-sm-min) {
      float: right;
      width: 290px;
    }
    @media(min-width: $screen-md-min) {
      width: 410px;
    }
    @media(min-width: $screen-lg-min) {
      width: 525px;
    }
  }

  .profile__adress {
    .addressfield-phone-block {
      display: none;
    }
  }

  .profile__bio {
    @media(min-width: $screen-sm-min) {
      margin-top: 30px;
    }
  }
}