.view-homepage-videos.view-display-id-block {
	@extend .container;

	> .view-content {
		@extend .row;
	}

	.more-link {
		text-align: center;
		a {
			@extend .btn, .btn-lg, .btn-primary;
		}
	}
}
