#block-views-sponsors-block-2 {
  .views-field-field-sponsor-photo {
	  padding: 10px;

	  a {
		  display: flex;
		  img {
			  margin: auto;
		  }
	  }
  }
}